import React from "react"

import "./layout.css"
import Logo from "../Logo"
import Contact from "../Contact"
import SEO from "../SEO"

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Logo />
      <div className="container">
        <main>{children}</main>
      </div>
      <Contact />
    </>
  )
}

export default Layout
