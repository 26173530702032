import React from "react"
import Layout from "../components/Layout"

const IndexPage = () => (
  <Layout>
    <p>We are a digital design studio proudly based in London, UK.</p>
    <p>
      We work with businesses of all sizes to develop creative online products
      and experiences.
    </p>
  </Layout>
)

export default IndexPage
