import React from "react"

const Contact = () => {
  return (
    <div className="contact">
      <a
        style={{ marginRight: 8 }}
        rel="noopener"
        href="mailto:craig@vekt.co.uk"
      >
        Contact
      </a>
      |
      <a
        style={{ marginLeft: 8 }}
        href="https://www.linkedin.com/company/vekt"
        target="_blank"
        rel="noopener"
      >
        LinkedIn
      </a>
    </div>
  )
}

export default Contact
