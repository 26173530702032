import React from "react"
import Helmet from "react-helmet"
import vektLogo from "../../images/vekt-logo-social.png"

function SEO() {
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "http://www.vekt.co.uk",
    sameAs: [`https://www.linkedin.com/company/vekt`],
    name: "Vekt",
    image: [vektLogo],
  }

  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>Vekt</title>
      <meta
        name="description"
        content="Digital design studio based in London, UK"
      />
      <meta property="og:title" content="Vekt" />
      <meta
        property="og:description"
        content="Digital design studio based in London, UK"
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={vektLogo} />
      <meta
        name="google-site-verification"
        content="m0ejJ19cuyX4G7datXoCXYH62FuoC3NZ9aBjpSc2EJc"
      />
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  )
}

export default SEO
