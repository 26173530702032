import React from "react"

const width = "90"
const height = "72"

const Logo = () => {
  return (
    <div className="logo">
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 195 125`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Vekt-logo"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polygon
            id="Rec-left"
            stroke="#000000"
            strokeWidth="2"
            points="62.2 0.993 123.4 62.5 62.2 124.007 1 62.5"
          ></polygon>
          <polygon
            id="Rec-right"
            stroke="#000000"
            strokeWidth="2"
            points="132.8 0.993 194 62.5 132.8 124.007 71.6 62.5"
          ></polygon>
        </g>
      </svg>
    </div>
  )
}

export default Logo
